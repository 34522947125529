<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <v-card id="activeRunwayCard" class="fill-height">
          <v-card-title>
            <v-icon class="pe-2"> mdi-road-variant</v-icon>
            Active Runway
            <v-spacer />
            <span
              v-if="runwayConfig.active?.lastUpdatedAt != null"
              class="text-body-2 grey--text"
              :title="runwayConfig.active?.lastUpdatedAt | formatDateTime"
            >
              Last updated
              {{ activeRunwayRelativeTime }}
            </span>
            <v-menu offset-y bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ms-2"
                  color="primary"
                  fab
                  x-small
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-rotate-left-variant</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in runwayConfig.runways"
                  :key="index"
                  @click="onActiveRunwayChange(item)"
                >
                  <v-list-item-title>
                    Set to {{ item.number }} {{ item.circuitDirection }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card color="#2680c630" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1">Runway</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-regular">
                          {{ runwayConfig.active?.number || "" }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card color="#26dac630" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1">Circuit</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-regular">
                          {{ runwayConfig.active?.circuitDirection || "" }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="8">
        <v-card id="weatherCard" class="fill-height">
          <v-card-title>
            <v-icon class="pe-2">mdi-gauge</v-icon>
            Conditions
            <v-spacer />
            <span
              v-if="weatherRecord?.date_time != null"
              class="text-body-2 grey--text"
              :title="weatherRecord?.date_time | formatDateTime"
            >
              Last updated at {{ weatherRecord?.date_time | formatTime }} ({{ weatherRelativeTime }}) from {{ weatherData.station || "METAR" }}
            </span>
            <v-btn
              class="ms-2"
              color="primary"
              fab
              x-small
              elevation="0"
              @click="onRefreshWeather()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="6" lg="">
                <v-card color="#26c68030" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1 text-no-wrap">QNH</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-regular">{{ weatherData?.altimeter?.value || "??" }}</span>
                        &nbsp;
                        <span class="text-body-2 font-weight-light">{{ weatherData?.units?.altimeter || "hPa" }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="6" lg="">
                <v-card color="#26c6da30" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1 text-no-wrap">QFE</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-regular">{{ altimeterAtAirfield || "??" }}</span>
                        &nbsp;
                        <span class="text-body-2 font-weight-light">{{ weatherData?.units?.altimeter || "hPa" }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" lg="">
                <v-card color="#26aa5028" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1 text-no-wrap"> Wind Speed</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-regular">{{ weatherData?.wind_speed?.value || "" }}</span>
                        <span v-if="weatherData?.wind_gust?.value != null">
                          &nbsp;
                          <span class="text-body-1 font-weight-thin">G</span>
                          <span class="text-h4 font-weight-regular">{{ weatherData?.wind_gust?.value }}</span>
                        </span>
                        &nbsp;
                        <span class="text-body-2 font-weight-light">{{ weatherData?.units?.wind_speed || "kt" }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="6" lg="">
                <v-card color="#26aa5028" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1 text-no-wrap"> Wind Direction</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-regular">{{ weatherData?.wind_direction?.value || "" }}°</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="6" lg="">
                <v-card color="#26aa5028" flat class="fill-height">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" class="text-body-1 text-no-wrap"> Crosswind</v-col>
                      <v-col cols="12" class="text-no-wrap">
                        <span class="text-h4 font-weight-light">
                          <v-icon v-if="Math.round(crosswind) === 0" small>mdi-arrow-left-right</v-icon>
                          <v-icon v-else-if="crosswind < 0.0">mdi-arrow-left-thin</v-icon>
                          <v-icon v-else-if="crosswind > 0.0">mdi-arrow-right-thin</v-icon>
                          <span class="text-h4 font-weight-regular">{{ Math.round(Math.abs(crosswind)) }}</span>
                          <span class="text-body-2 font-weight-light ps-1">
                            {{ weatherData?.units?.wind_speed || "kt" }}
                          </span>
                          <v-icon class="ps-1" v-if="headwind > 0.0">mdi-arrow-down-thin</v-icon>
                          <v-icon class="ps-1" v-else-if="headwind < 0.0">mdi-arrow-up-thin</v-icon>
                          <v-icon class="ps-1" v-else small>mdi-arrow-up-down</v-icon>
                          <span class="text-h4 font-weight-regular">{{ Math.round(Math.abs(headwind)) }}</span>
                          <span class="text-body-2 font-weight-light ps-1">
                            {{ weatherData?.units?.wind_speed || "kt" }}
                          </span>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card id="arrivalsCard" class="fill-height">
          <v-card-title>
            <v-icon class="pe-2"> mdi-airplane-landing</v-icon>
            Arrivals
            <v-spacer />
            <v-btn
              color="primary"
              fab
              x-small
              elevation="0"
              to="/admin/arrivals/new"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <arrivals-list
              :items="arrivals"
              :show-date="false"
              :show-captain="false"
              :show-status="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card id="departuresCard" class="fill-height">
          <v-card-title>
            <v-icon class="pe-2"> mdi-airplane-takeoff</v-icon>
            Departures
            <v-spacer />
            <v-btn
              color="primary"
              fab
              x-small
              elevation="0"
              to="/admin/departures/new"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <departures-list
              :items="departures"
              :show-date="false"
              :show-captain="false"
              :show-status="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { supabase } from "@/supabase";
import ArrivalsList from "../../components/ArrivalsList.vue";
import DeparturesList from "../../components/DeparturesList.vue";

function calculateWindComponents(runwayDirection, windDirection, windSpeed) {
  // Convert angles to radians
  const runwayRad = (runwayDirection * Math.PI) / 180;
  const windDirRad = (windDirection * Math.PI) / 180;

  // Calculate the angle between the wind direction and the runway direction
  const angleBetween = runwayRad - windDirRad;

  // Calculate crosswind and headwind components
  const crosswind = windSpeed * Math.sin(angleBetween); // positive is from the left, negative is from the right
  const headwind = windSpeed * Math.cos(angleBetween); // positive is headwind, negative is tailwind

  return { crosswind, headwind };
}

export default {
  name: "Tower",
  components: {
    ArrivalsList,
    DeparturesList,
  },

  beforeDestroy() {
    this.$store.dispatch("tower/unsubscribe");
  },

  watch: {
    airfieldId(value) {
      if (value) {
        this.$store.dispatch("tower/subscribe", value);
      } else {
        this.$store.dispatch("tower/unsubscribe");
      }
    },
  },

  created() {
    const updateTimes = () => {
      this.activeRunwayRelativeTime = this.formatRelativeTime(
        this.runwayConfig?.active?.lastUpdatedAt
      );
      this.weatherRelativeTime = this.formatRelativeTime(
        this.weatherRecord?.date_time
      );
    };
    setTimeout(updateTimes, 250);
    this.interval = setInterval(updateTimes, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },

  data() {
    return {
      interval: null,
      activeRunwayRelativeTime: null,
      weatherRelativeTime: null,
    };
  },

  computed: {
    ...mapGetters({
      arrivals: "tower/arrivals",
      departures: "tower/departures",
      weatherRecord: "tower/weather",
      runwayConfig: "auth/runwayConfig",
      weatherConfig: "auth/weatherConfig",
      airfieldId: "auth/airfieldId",
    }),
    weatherData: function () {
      return this.weatherRecord?.weather_data
    },
    altimeterAtAirfield() {
      // crude conversion between QNH to QFE using 27ft/hPa
      // TODO: find something better
      const elevation = this.weatherConfig?.airfieldElevation || 0
      const diffHPA = elevation / 27
      return Math.round((this.weatherData?.altimeter?.value || 0) - diffHPA)
    },
    runwayDegrees() {
      let rDir = parseInt(this.runwayConfig?.active?.number || "0") * 10
      if (rDir === 0) rDir = 360;
      return rDir;
    },
    windDegrees() {
      return this.weatherData?.wind_direction?.value || 0;
    },
    crosswind() {
      const rDir = this.runwayDegrees
      const wDir = this.windDegrees
      const speed = this.weatherData?.wind_speed?.value || 0
      return calculateWindComponents(rDir, wDir, speed).crosswind
    },
    headwind() {
      const rDir = this.runwayDegrees
      const wDir = this.windDegrees
      const speed = this.weatherData?.wind_speed?.value || 0
      return calculateWindComponents(rDir, wDir, speed).headwind
    },
  },

  methods: {
    formatRelativeTime(value) {
      if (!value) return "";
      return DateTime.fromISO(value).toRelative();
    },

    async onActiveRunwayChange(item) {
      const runwayConfig = Object.assign({}, this.runwayConfig);
      runwayConfig.active = {
        number: item.number,
        circuitDirection: item.circuitDirection,
        lastUpdatedAt: DateTime.utc().toISO(),
      };

      await this.$store.dispatch("auth/saveRunwayConfig", runwayConfig);
    },

    async onRefreshWeather() {
      const { error } = await supabase.functions.invoke(
        "weather-refresh",
        {
          body: {
            id: this.airfieldId,
            code: this.weatherConfig.icaoCode,
          },
        }
      );

      if (error) console.error(error);
    },
  },

  filters: {
    formatDateTime(value) {
      if (!value) return "";
      return DateTime.fromISO(value).toLocal().toLocaleString(DateTime.DATETIME_FULL);
    },
    formatTime(value) {
      if (!value) return "";
      return DateTime.fromISO(value).toLocal().toLocaleString(DateTime.TIME_24_SIMPLE);
    },
  },
};
</script>
